import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
    Box,
    Typography,
    Button,
    TableRow,
    TableCell,
    Dialog,
} from "@mui/material";
import MapView from "./map"


const myStyle = {
    background: "#e6e9ff",
    padding: "8px",
    borderRadius: "5px",
    color: "#283593",
    width: "98%",
    display: 'flex',
    justifyContent: 'space-between'
};

const KioskMap = ({ onSelect, mapType, onClose }) => {
    const mapRef = React.useRef();


    const [showLockerDetails, setShowLockerDetails] = useState(true);
    const [lockerID, setLockerID] = useState("")
    const [mapLoaded, setMapLoaded] = useState(false);
    const [selectedLocker, setSelectedLocker] = useState(null);
    const [lockerDetailsModal, setLockerDetailsModal] = useState('');

    const handleCloseDetailsModal = () => {
        setLockerDetailsModal(false);
    }

    let lockerList = JSON.parse(localStorage.getItem("lockers"));

    const handleOnLocker = (id) => {
        setLockerID(id);
        setShowLockerDetails(true)


        let lockers = lockerList;

        let lockerSelected = lockers.filter(
            (item) => item.id === id
        );

        if (lockerSelected && lockerSelected.length > 0) {
            setSelectedLocker(lockerSelected[0])
        }
    };

    const onLoad = React.useCallback(function callback(map) {
        setMapLoaded(map);
    }, []);

    const ShowLockerDetails = () => {
        if (lockerID.length > 0) {
            let lockersNew = lockerList;

            let lockerFilter = lockersNew.filter(
                (item) => item.id === lockerID
            );

            const handleMapLinkClick = () => {
                setShowLockerDetails(false);
                onClose(selectedLocker);
            };

            console.log('My Locker: ', lockerFilter);


            const TradingHours = () => {
                var tradingHoursView = [];

                let tradingHours = lockerFilter[0].standardHours;

                tradingHours.forEach((item, i) => {
                    item.id = i + 1;
                });

                tradingHours = tradingHours.sort(
                    (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10)
                );

                for (let i = 0; i < tradingHours.length; i++) {
                    let hours;
                    if (
                        tradingHours[i].openingTime === "00:00:00" &&
                        tradingHours[i].closingTime === "00:00:00"
                    ) {
                        hours = "Closed";
                    } else {
                        hours = `${tradingHours[i].openingTime} -  ${tradingHours[i].closingTime}`;
                    }
                    if (
                        tradingHours[i].openingTime === "00:00:00" &&
                        tradingHours[i].closingTime === "23:59:00"
                    ) {
                        hours = "24 hours";
                    }
                    tradingHoursView.push(
                        <TableRow key={i}>
                            <TableCell style={{ fontSize: '12px' }}>{tradingHours[i].day}</TableCell>
                            <TableCell style={{ fontSize: '12px' }}>{hours}</TableCell>
                        </TableRow>
                    );

                    if (tradingHours.length === 0)
                        tradingHoursView.push(
                            <TableRow key={i}>
                                <TableCell>No Trading Hours</TableCell>
                            </TableRow>
                        );
                }

                return tradingHoursView;
            };

            if (showLockerDetails === true) {

                return (
                    <Dialog open={showLockerDetails} onClose={handleCloseDetailsModal} maxWidth={'lg'}>
                        <AppBar sx={{ position: 'relative', backgroundColor: 'transparent' }}>
                            <Toolbar sx={myStyle}>
                                <Typography variant="h6" >
                                    {" "}
                                    Locker Details
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setShowLockerDetails(false)}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <Box sx={{ pl: 2 }}>

                            <Box sx={{ p: 2 }}>
                                <Typography variant="h6">
                                    {lockerFilter[0].name}
                                </Typography>
                                <Box sx={{ pt: 1 }}>
                                    <Typography>
                                        {lockerFilter[0].address.address}
                                    </Typography>
                                </Box>
                                <Box sx={{ pt: 1 }}>
                                    <Typography fontWeight={"bold"}>
                                        Trading Hours
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 1 }}>
                                    <TradingHours />
                                </Box>

                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{ mt: 2, backgroundColor: '#264796' }}
                                    onClick={handleMapLinkClick}
                                >
                                    Select
                                </Button>

                            </Box>
                        </Box>
                    </Dialog>
                );
            } else {
                return;
            }
        }
    };

    return (
        <>
            <Box width="100%" sx={{ display: "flex", flexDirection: "column" }}>

                <Box>
                    <Box p={2}>
                        {mapLoaded && <AutocompleteComp mapRef={mapRef} textSearch={mapType} />}
                    </Box>
                    <MapView
                        handleOnLocker={handleOnLocker}
                        mapRef={mapRef}
                        onLoad={onLoad}
                        toDisplay={mapType}
                    />
                </Box>

                <Box width={"100%"}>
                    <ShowLockerDetails />
                </Box>
            </Box>
        </>
    );
};

const AutocompleteComp = ({ mapRef, textSearch }) => {
    let placeText = "Search for a Locker"
    if (textSearch === 'kiosk') {
        placeText = "Search for a Kiosk"
    }

    const [autocomplete, setAutocomplete] = useState(null);

    console.log("autocomplete render");
    const autocompleteOnLoad = React.useCallback(function callback(
        autocompletedata
    ) {
        console.log("autocompleteOnLoad");
        setAutocomplete(autocompletedata);
    },
        []);

    const onPlaceChanged = () => {
        console.log("onPlaceChanged");
        if (autocomplete !== null) {
            const placeInfo = autocomplete.getPlace();
            if (placeInfo.hasOwnProperty("geometry")) {
                mapRef.current.state.map.setOptions({
                    center: placeInfo.geometry.location,
                    zoom: 14,
                });
            } else {
                console.log("Searched place does not have a geometry data!");
            }
        } else {
            console.log("Autocomplete is not loaded yet!");
        }
    };

    return (
        <Autocomplete
            onLoad={autocompleteOnLoad}
            onPlaceChanged={onPlaceChanged}
        >
            <input
                type="text"
                placeholder={placeText}
                style={{ width: '90vw', height: 40 }}
            />
        </Autocomplete>
    );
};

export default KioskMap;
