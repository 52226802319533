import axios from 'axios';


const sendSMSNotifications = (mobile, waybill) => {

    let apiKey = '94556471-4669-4c42-b4b6-686a010f515c';
    let apiSecret = '0f390945-7f89-49f0-a95b-7ad0ab835765';
let accountApiCredentials = apiKey + ':' + apiSecret;

const base64Credentials = btoa(accountApiCredentials);

let requestHeaders = {
    headers: {
        'Authorization': `Basic ${base64Credentials}`,
        'Content-Type': 'application/json'
    }
};

let requestData = JSON.stringify({
    messages: [{
        content: `The Courier Guy :Thank you for using our shipment pre-capture portal! Please present your shipment number to our friendly kiosk assistant. They'll handle the weights and dimensions, you settle the payment, and we'll take care of the rest quick and easy! Shipment Number: ${waybill}`,
        destination: `${mobile}`
    }]
});

axios.post('https://rest.smsportal.com/bulkmessages', requestData, requestHeaders)

    .then(response => {
        if (response.data) {
            console.log("Success:");
           // console.log(response.data);
        }
    })
    .catch(error => {
        if (error.response) {
            console.log("Failure:");
          //  console.log(error.response.data);
        } else {
            console.log("Something went wrong during the network request.");
        }
    });
}

export default sendSMSNotifications