import React, { useMemo } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    MarkerClusterer,
    Marker,
} from "@react-google-maps/api";
import { Box, Skeleton } from "@mui/material";



const LIBRARIES = ["places", "geometry"];

const MapView = ({ handleOnLocker, mapRef, onLoad, toDisplay }) => {

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDQlN9kn4F9bsp3RRhFwqFrRJotz-qtAGY",
        libraries: LIBRARIES,
        region: "za",
    });

    const SouthAfrica = useMemo(
        () => ({ lat: -28.9172196, lng: 23.3879977 }),
        []
    );


  let lockerList = JSON.parse(localStorage.getItem("lockers"));

    let filteredLockers = lockerList.filter(locker => locker.lockerId.startsWith('C'));
    let iconMap = require("../images/pudo_marker.png")

    if(toDisplay === 'kiosk'){

   filteredLockers = lockerList.filter(locker => locker.lockerId.startsWith('K'));
        iconMap = require("../images/tcg_marker.png")
    }
    const markers = filteredLockers

     //console.log("TalentMarker", markers);

    // const handleOnLoad = (map) => {
    //   const bounds = new google.maps.LatLngBounds();
    //   markers.forEach(({ position }) => bounds.extend(position));
    //   map.fitBounds(bounds);
    // };

    const Map = () => {
        return (
            <Box>
                <GoogleMap
                    zoom={5}
                    center={SouthAfrica}
                    mapContainerStyle={{
                        height: "80vh",
                        width: "100vw",
                    }}
                    options={{
                        zoomControl: true,
                        fullscreenControl: true,
                        rotateControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                    }}
                    //onClick={() => setActiveMarker(null)}
                    onLoad={onLoad}
                    ref={mapRef}
                >
                    <MarkerClusterer>
                        {(clusterer) =>
                            markers.map(({ id, name, position }) => {
                                if (
                                    position.lat === null &&
                                    position.lng === null
                                ) {
                                    return;
                                }
                                return (
                                    <Marker
                                        key={id}
                                        position={position}
                                        icon={{
                                            url: iconMap,
                                            scaledSize:
                                                new window.google.maps.Size(
                                                    25,
                                                    40
                                                ),
                                        }}
                                        title={name}
                                        // onMouseOut={handleMouseExit}
                                        onClick={() => handleOnLocker(id)}
                                        clusterer={clusterer}
                                    />
                                );
                            })
                        }
                    </MarkerClusterer>
                </GoogleMap>
            </Box>
        );
    };

    if (!isLoaded)
        return (
            <div>
                <Skeleton
                    variant="rectangular"
                    height={"70vh"}
                    width={"100vw"}
                />
            </div>
        );
    return <Map />;
};

export default React.memo(MapView, (prevProps, nextProps) => {
    return true; // donot re-render
});
