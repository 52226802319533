import React, { useState } from 'react';
import { Box, TextField, Autocomplete, Button, Dialog } from '@mui/material';
import KioskMap from '../components/KioskMap'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';


function ReceivingKiosk({ selectedKiosk, setSelectedKiosk }) {
  const [kioskmap, setKioskMap] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [kiosks, set_kiosks] = useState([]);
  const stored_kiosks = localStorage.getItem('lockers');

  React.useEffect(() => {
    if (stored_kiosks) {
      const kiosksInfo = JSON.parse(stored_kiosks);
      if (kiosksInfo && kiosksInfo.length > 0) {
        const _kiosk = kiosksInfo.filter(data => data.lockerId && data.lockerId.startsWith("K"));
        set_kiosks(_kiosk);
      }
    }
  }, [stored_kiosks]);

  const handleKioskMap = () => {
    setKioskMap(true)
  }

  const handleCloseKioskMap = (selectedKiosk) => {
    setKioskMap(false)
    setSelectedKiosk(selectedKiosk)
    if (selectedKiosk) {
      setSelectedName(selectedKiosk.name);
    }
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Autocomplete
        value={selectedKiosk}
        disablePortal
        id="locker"
        options={kiosks}
        getOptionLabel={(options) => options.name || selectedName}
        sx={{ width: '75vw' }}
        onChange={(event, newValue) => {
          setSelectedKiosk(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a Kiosk"
          />
        )}
      />

      <Button variant='contained' sx={{ width: '25vw', backgroundColor: '#ed7d31' }} onClick={() => handleKioskMap()}>
        Kiosk Map
      </Button>

      <Dialog open={kioskmap} onClose={() => setKioskMap(false)} fullScreen>
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#264796' }}>
            <Typography>Kiosk Map</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setKioskMap(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ mt: 7 }}>
          <KioskMap mapType={'kiosk'} onClose={handleCloseKioskMap} />
        </Box>

      </Dialog>
    </Box>

  );
}

export default ReceivingKiosk;
