import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.shiplogic.com/v2/', 
  timeout: 5000,  
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer c1e31d5be7c2429b9f79d7055098bbe6'
  }
});

export default axiosInstance;
