import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import ReceivingLocker from "./ReceivingLocker";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const myStyle = {
  background: "#264796",
  padding: "8px",
  borderRadius: "5px",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
};

const PageTitle = () => (
  <Typography variant="h6" style={myStyle} fontWeight={"bold"}>
    Receiver Details
  </Typography>
);

function ReceiverDetails({
  receiverName,
  setReceiverName,
  receiverNumber,
  setReceiverNumber,
  receiverEmail,
  setReceiverEmail,
  selectedLocker,
  setSelectedLocker,
  rechelperText,
  recerror,
  recphoneError,
  recphoneHelperText,
  handleRecEmailChange,
  handleRecPhoneChange
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <PageTitle />
      <ReceivingLocker
        selectedLocker={selectedLocker}
        setSelectedLocker={setSelectedLocker}
      />

      <TextField
        required
        id="receiver_name"
        label="Receiver Name"
        value={receiverName}
        onChange={(e) => setReceiverName(e.target.value)}
      />
      <PhoneInput
        placeholder="Enter phone number"
        value={receiverNumber}
        onChange={handleRecPhoneChange}
        countryCallingCodeEditable={false}
        international
        defaultCountry="ZA"
        className={recphoneError ? "phone-input-error" : ""}
      />
      {recphoneError && <div style={{ color: "red" }} className="HelperText">{recphoneHelperText}</div>}

      <TextField
        id="receiver_email"
        label="Receiver Email"
        value={receiverEmail}
        onChange={handleRecEmailChange}
        error={recerror}
        helperText={rechelperText}
        fullWidth
        required
      />

    </Box>
  );
}

export default ReceiverDetails;
