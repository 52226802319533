import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import ReceivingDoor from "./ReceivingDoor";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const myStyle = {
  background: "#264796",
  padding: "8px",
  borderRadius: "5px",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
};

const PageTitle = () => (
  <Typography variant="h6" style={myStyle} fontWeight={"bold"}>
    Receiver Details
  </Typography>
);

function ReceiverDetailsDoor({
  receiverName,
  setReceiverName,
  receiverNumber,
  setReceiverNumber,
  receiverEmail,
  setReceiverEmail,
  streetAddress,
  setStreetAddress,
  companyName,
  setCompanyName,
  complex,
  setComplex,
  suburb,
  setSuburb,
  city,
  setCity,
  province,
  setProvince,
  postCode,
  setPostCode,
  deliveryInstruction,
  setDeliveryInstruction,
  addressType,
  setAddressType,
  rechelperText,
  recerror,
  recphoneError,
  recphoneHelperText,
  handleRecEmailChange,
  handleRecPhoneChange
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <PageTitle />
      <ReceivingDoor
        streetAddress={streetAddress}
        setStreetAddress={setStreetAddress}
        companyName={companyName}
        SetCompanyName={setCompanyName}
        complex={complex}
        setComplex={setComplex}
        suburb={suburb}
        setSuburb={setSuburb}
        city={city}
        setCity={setCity}
        province={province}
        setProvince={setProvince}
        postCode={postCode}
        setPostCode={setPostCode}
        deliveryInstruction={deliveryInstruction}
        setDeliveryInstruction={setDeliveryInstruction}
        addressType={addressType}
        setAddressType={setAddressType}
      />

      <Box sx={{ borderBottom: "1px solid lightgrey", m: 2 }} />

      <TextField
        required
        id="receiver_name"
        label="Receiver Name"
        value={receiverName}
        onChange={(e) => setReceiverName(e.target.value)}
      />
      <PhoneInput
        placeholder="Enter phone number"
        value={receiverNumber}
        onChange={handleRecPhoneChange}
        countryCallingCodeEditable={false}
        international
        defaultCountry="ZA"
        className={recphoneError ? "phone-input-error" : ""}
      />
      {recphoneError && <div style={{ color: "red" }} className="HelperText">{recphoneHelperText}</div>}

      <TextField
        id="receiver_email"
        label="Receiver Email"
        value={receiverEmail}
        onChange={handleRecEmailChange}
        error={recerror}
        helperText={rechelperText}
        fullWidth
        required
      />
    </Box>
  );
}

export default ReceiverDetailsDoor;
