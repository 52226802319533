import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
//import "react-phone-number-input/style.css";

const myStyle = {
  background: "#264796",
  padding: "8px",
  borderRadius: "5px",
  color: "#ffffff",
  display: "flex",
  alignItems: "center",
};

const PageTitle = () => (
  <Typography variant="h6" style={myStyle} fontWeight={"bold"}>
    Sender Details
  </Typography>
);

function SenderDetails({
  senderName,
  setSenderName,
  senderNumber,
  setSenderNumber,
  senderEmail,
  setSenderEmail,
  error,
  phoneError,
  helperText,
  handleEmailChange,
  handlePhoneChange,
  phoneHelperText,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <PageTitle />
      <TextField
        required
        id="sender_name"
        label="Sender Name"
        value={senderName}
        onChange={(e) => setSenderName(e.target.value)}
      />

      <PhoneInput
        placeholder="Enter phone number"
        value={senderNumber}
        onChange={handlePhoneChange}
        countryCallingCodeEditable={false}
        international
        defaultCountry="ZA"
        className={phoneError ? "phone-input-error" : ""}
      />
      {phoneError && <div style={{ color: "red" }}>{phoneHelperText}</div>}

      <TextField
        id="sender_email"
        label="Sender Email"
        value={senderEmail}
        onChange={handleEmailChange}
        error={error}
        helperText={helperText}
        fullWidth
        required
      />
    </Box>
  );
}

export default SenderDetails;
