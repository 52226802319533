import React, { useState } from "react";
import { Box, TextField, Autocomplete, Button } from "@mui/material";

function ReceivingDoor({
  streetAddress,
  setStreetAddress,
  complex,
  setComplex,
  companyName,
  setCompanyName,
  suburb,
  setSuburb,
  city,
  setCity,
  province,
  setProvince,
  postCode,
  setPostCode,
  deliveryInstruction,
  setDeliveryInstruction,
  addressType,
  setAddressType
}) {
  const provinceData = [
    { id: "EC", label: "Eastern Cape" },
    { id: "FS", label: "Free State" },
    { id: "GP", label: "Gauteng" },
    { id: "KZN", label: "KwaZulu-Natal" },
    { id: "LP", label: "Limpopo" },
    { id: "MP", label: "Mpumalanga" },
    { id: "NW", label: "North West" },
    { id: "NC", label: "Northern Cape" },
    { id: "WC", label: "Western Cape" },
  ];


  const [bVarient, setBVarient] = useState("outlined");
  const [iVarient, setIVarient] = useState("contained");

  const handleBusinessBtn = () => {
    setAddressType("business");
    setBVarient("contained");
    setIVarient("outlined");
  };

  const handleIndividualBtn = () => {
    setAddressType("residential");
    setIVarient("contained");
    setBVarient("outlined");
  };

  const handleProvinceChange = (event, value) => {
    if (value) {
      setProvince(value.label);
    } else {
      setProvince("");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
      <Box sx={{ display: "flex", mb: 2, gap: 2 }}>
        <Box sx={{ width: "49%" }}>
          <Button
            fullWidth
            variant={iVarient}
            sx={{backgroundColor: iVarient === 'contained'? '#1565C0' : ''}}
            onClick={() => handleIndividualBtn()}
          >
           Residential
          </Button>
        </Box>
        <Box sx={{ width: "49%" }}>
          <Button
            variant={bVarient}
            fullWidth
            sx={{backgroundColor: bVarient === 'contained'? '#1565C0' : ''}}
            onClick={() => handleBusinessBtn()}
          >
           Business
          </Button>
        </Box>
      </Box>

 { addressType === "business" &&   <TextField
        id="company_name"
        label="Company (Optional)"
        value={companyName}
        onChange={(e) => setCompanyName(e.target.value)}
        fullWidth
      />}
      <TextField
        id="complex"
        label="Complex / Building"
        value={complex}
        onChange={(e) => setComplex(e.target.value)}
        fullWidth
      />

      <TextField
        required
        id="receiver_street_address"
        label="Receiver Street Address"
        value={streetAddress}
        onChange={(e) => setStreetAddress(e.target.value)}
        fullWidth
      />

      <TextField
        required
        id="receiver_suburb"
        label="Receiver Suburb"
        value={suburb}
        onChange={(e) => setSuburb(e.target.value)}
        fullWidth
      />

      <TextField
        required
        id="receiver_city"
        label="Receiver City"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        fullWidth
      />

      <Autocomplete
        isOptionEqualToValue={(id, label) =>
          provinceData.id === provinceData.label
        }
        required
        id="receiver_province"
        options={provinceData}
        value={province}
        onChange={handleProvinceChange}
        renderInput={(params) => <TextField {...params} label="Province" />}
      />
      <TextField
        required
        id="receiver_post_code"
        label="Receiver Postal Code"
        value={postCode}
        onChange={(e) => setPostCode(e.target.value)}
        fullWidth
      />

      <TextField
        id="delivery_instuction"
        label="Delivery Instruction"
        value={deliveryInstruction}
        onChange={(e) => setDeliveryInstruction(e.target.value)}
        fullWidth
      />
    </Box>
  );
}

export default ReceivingDoor;
