import React, { useState } from 'react';
import { Box, TextField, Autocomplete, Button, Dialog } from '@mui/material';
import KioskMap from '../components/KioskMap'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';


function ReceivingKiosk({ selectedLocker, setSelectedLocker }) {
  const [kioskmap, setKioskMap] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [lockers, set_lockers] = useState([]);
  const stored_lockers = localStorage.getItem('lockers');

  React.useEffect(() => {
    if (stored_lockers) {
      const lockersInfo = JSON.parse(stored_lockers);
      if (lockersInfo && lockersInfo.length > 0) {
        const _lockers = lockersInfo.filter(data => data.lockerId && !data.lockerId.startsWith("K"));
        set_lockers(_lockers);
      }

    }
  }, [stored_lockers]);

  const handleKioskMap = () => {
    setKioskMap(true)
  }

  const handleCloseKioskMap = (selectedLocker) => {
    setKioskMap(false)
    setSelectedLocker(selectedLocker)
    if (selectedLocker) {
      setSelectedName(selectedLocker[0].name);
    }
  }

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Autocomplete
        value={selectedLocker}
        disablePortal
        id="locker"
        options={lockers}
        isOptionEqualToValue={(options) => options.name}
        getOptionLabel={(options) => options.name || selectedName}
        sx={{ width: '75vw' }}
        onChange={(event, newValue) => {
          setSelectedLocker(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a Locker"
          />
        )}
      />
      <Button
        variant='contained'
        sx={{
          width: '25vw',
          backgroundColor: '#ed7d31'
        }}
        onClick={() => handleKioskMap()}
      >
        Locker Map
      </Button>
      <Dialog open={kioskmap} onClose={() => setKioskMap(false)} fullScreen>
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#264796' }}>
            <Typography>Locker Map</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setKioskMap(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ mt: 7 }}>
          <KioskMap mapType={'locker'} onClose={handleCloseKioskMap} />
        </Box>
      </Dialog>
    </Box>
  );
}

export default ReceivingKiosk;
