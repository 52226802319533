import logo from "./components/images/logo.jpg";
import "./App.css";
import React, { Fragment, useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Modal,
} from "@mui/material";
import SenderDetails from "./components/SenderDetails";
import ReceiverDetailsKiosk from "./components/ReceiverDetailsKiosk";
import ReceiverDetailsDoor from "./components/ReceiverDetailsDoor";
import ReceiverDetailsLocker from "./components/ReceiverDetailsLocker";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import axiosInstance from "./api/axiosConfig";
import { makeStyles } from "@material-ui/core/styles";
import { isValidPhoneNumber } from "react-phone-number-input";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import sendSMSNotifications from "./api/sendNotification";

const useStyles = makeStyles((theme) => ({
  k2l: {
    "& > *": {
      boxShadow:
        "0px 3px 1px -2px #F4AB00,0px 2px 2px 0px #FACD64,0px 1px 5px 0px #FACD64",
      padding: 32,
      borderRadius: 4,
    },
  },

  k2d: {
    "& > *": {
      boxShadow:
        "0px 3px 1px -2px #264796,0px 2px 2px 0px #264796c9,0px 1px 5px 0px #264796c9",
      padding: 32,
      borderRadius: 4,
    },
  },
  k2k: {
    "& > *": {
      boxShadow:
        "0px 3px 1px -2px #ed7e30,0px 2px 2px 0px #ed7e30d1,0px 1px 5px 0px #ed7e30d1",
      padding: 32,
      borderRadius: 4,
    },
  },
}));

function App() {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [senderName, setSenderName] = useState("");
  const [senderNumber, setSenderNumber] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverNumber, setReceiverNumber] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [complex, setComplex] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [selectedKiosk, setSelectedKiosk] = useState("");
  const [selectedLocker, setSelectedLocker] = useState("");
  const [province, setProvince] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deliveryInstruction, setDeliveryInstruction] = useState("");
  const [addressType, setAddressType] = useState("residential");

  const [modalOpen, setModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    short_tracking_reference: "",
  });
  const [error, setError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [phoneHelperText, setPhoneHelperText] = useState("");
  const [helperText, setHelperText] = useState("");
  const [recerror, setRecError] = useState(null);
  const [recphoneError, setRecPhoneError] = useState(null);
  const [recphoneHelperText, setRecPhoneHelperText] = useState("");
  const [rechelperText, setRecHelperText] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };

  const closeErrorModal = () => {
    setErrorModal(false);
  }

  const handleTerms = () => {
    const mapUrl = `https://thecourierguy.co.za/terms-conditions`;
    window.open(mapUrl, "_blank");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setSenderEmail(newEmail);
    if (validateEmail(newEmail)) {
      setError(false);
      setHelperText("");
    } else {
      setError(true);
      setHelperText("Invalid email address");
    }
  };

  const handleRecEmailChange = (event) => {
    const newEmail = event.target.value;
    setReceiverEmail(newEmail);
    if (validateEmail(newEmail)) {
      setRecError(false);
      setRecHelperText("");
    } else {
      setRecError(true);
      setRecHelperText("Invalid email address");
    }
  };

  const handlePhoneChange = (newPhoneNumber) => {
    setSenderNumber(newPhoneNumber);

    if (
      newPhoneNumber &&
      isValidPhoneNumber(newPhoneNumber) &&
      newPhoneNumber.length === 12
    ) {
      setPhoneError(false);
      setPhoneHelperText("");
    } else {
      setPhoneError(true);
      setPhoneHelperText("Invalid phone number or incorrect length");
    }
  };

  const handleRecPhoneChange = (newRecPhoneNumber) => {
    setReceiverNumber(newRecPhoneNumber);
    if (
      newRecPhoneNumber &&
      isValidPhoneNumber(newRecPhoneNumber) &&
      newRecPhoneNumber.length === 12
    ) {
      setRecPhoneError(false);
      setRecPhoneHelperText("");
    } else {
      setRecPhoneError(true);
      setRecPhoneHelperText("Invalid phone number or incorrect length");
    }
  };

  const ClearFields = () => {
    setSenderName("");
    setSenderEmail("");
    setSenderNumber("");
    setReceiverName("");
    setReceiverEmail("");
    setReceiverNumber("");
    setStreetAddress("");
    setSuburb("");
    setCompanyName("");
    setComplex("");
    setCity("");
    setProvince("");
    setPostCode("");
    setSelectedLocker("");
    setSelectedKiosk("");
    setDeliveryInstruction("");
    setAddressType("residential");
    setError(null);
    setAccepted(false)
  };

  React.useEffect(() => {
    const getLockers = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://api-pudo.co.za/api/v1/lockers-data",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer 1324|OkDOyIdD0JuJ19vrNdswlB34lWR26cWVFDBjnJTk31eaa6d1",
            }
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        if (response.status === 200) {
          const data = await response.json();
          const mapped_lockers = data.map(locker => {
            const {
              latitude = null,
              longitude = null,
              name = "",
              code = "",
              type,
              openinghours = []
            } = locker || {}
            const { formatted_address = "", ...restssof_address } = locker.detailed_address || {}

            return {
              "id": code,
              "lockerId": code,
              "name": name,
              "isActive": true,
              "lstTypesBoxes": locker?.lstTypesBoxes,
              "position": {
                "lat": parseFloat(latitude),
                "lng": parseFloat(longitude)
              },
              "type": type,
              "address": {
                "address": formatted_address,
                "latitude": parseFloat(latitude),
                "longitude": parseFloat(longitude),
                ...restssof_address
              },
              "standardHours": openinghours.map(day => {
                return {
                  day: day?.day,
                  openingTime: day?.open_time,
                  closingTime: day?.close_time
                }
              })
            }
          });
          localStorage.setItem(
            "lockers",
            JSON.stringify(mapped_lockers)
          );
        } else {
          console.log(response?.message || "Request code failed!", {
            variant: "error",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      } finally {
        setLoading(false);
      }
    };
    getLockers();
  }, []);

  const handleBackStep = (currentStep) => {
    setCurrentStep(0);
  };

  const sendSMS = (mobile, waybill) => {
    sendSMSNotifications(mobile, waybill)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let today = new Date();
    today.setDate(today.getDate() + 5);
    console.log(today.toString());
    const sLData = {
      collection_address: {
        type: "counter",
        company: "Kiosk Pre-Booking [Change to your cash account]",
        street_address: "Pre Booking",
        local_area: "Pre Booking",
        city: "Pre Booking",
        code: "0000",
        zone: "Gauteng",
        country: "ZA",
        lat: -26.004105079236897,
        lng: 27.940885290245756,
      },
      delivery_address: {
        type:
          selectedKiosk !== ""
            ? "counter"
            : selectedLocker !== ""
              ? "locker"
              : addressType,
        company:
          selectedKiosk !== ""
            ? `Kiosk: ${selectedKiosk.name}`
            : selectedLocker !== ""
              ? `Locker: ${selectedLocker.name}`
              : companyName,
        street_address:
          selectedKiosk !== ""
            ? selectedKiosk.address.address
            : selectedLocker !== ""
              ? selectedLocker.address.address
              : complex + " " + streetAddress,
        local_area: suburb !== "" ? suburb : "",
        city: city !== "" ? city : "",
        zone: province !== "" ? province : "",
        country: "ZA",
        code: postCode !== "" ? postCode : "",
        lat:
          selectedKiosk !== ""
            ? selectedKiosk.address.latitude
            : selectedLocker !== ""
              ? selectedLocker.address.latitude
              : 0,
        lng:
          selectedKiosk !== ""
            ? selectedKiosk.address.longitude
            : selectedLocker !== ""
              ? selectedLocker.address.longitude
              : 0,
      },
      parcels: [
        {
          parcel_description: "",
          submitted_length_cm: 1,
          submitted_width_cm: 1,
          submitted_height_cm: 1,
          submitted_weight_kg: 1,
        },
      ],
      status: "payment-pending",
      declared_value: 0,
      custom_tracking_reference: "",
      special_instructions_collection: "",
      special_instructions_delivery:
        deliveryInstruction !== "" ? deliveryInstruction : "",
      service_level_code: "ECO",
      // account_id: 44404,
      collection_contact: {
        name: senderName,
        mobile_number: senderNumber,
        email: senderEmail,
      },
      delivery_contact: {
        name: receiverName,
        mobile_number: receiverNumber,
        email: receiverEmail,
      },
      collection_min_date: `${today.toISOString()}`,
      collection_after: "08:00",
      collection_before: "18:00",
      mute_notifications: true,
    };

    console.log("Form Details: ", sLData);
    fetchDataFromAPI("shipments", sLData);
  };

  const fetchDataFromAPI = async (url, sLData) => {
    if (
      validateEmail(senderEmail) &&
      senderNumber.length === 12 &&
      validateEmail(receiverEmail) &&
      receiverNumber.length === 12
    ) {
      try {
        const response = await axiosInstance.post(url, sLData);
        console.log("API Response:", response.data);
        setApiResponse(response.data);
        setModalOpen(true);
        ClearFields();
        sendSMS(senderNumber, response.data.short_tracking_reference)
        setCurrentStep(0);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        if (error.response) {
          console.error("Error response data:", error.response.data);
          setErrorMessage(error.response.data);
          setErrorModal(true);
        }
      }
    } else {
      if (!validateEmail(senderEmail)) {
        setError(true);
        setHelperText("Invalid email address");
      }

      if (!validateEmail(receiverEmail)) {
        setRecError(true);
        setRecHelperText("Invalid email address");
      }

      if (senderNumber.length !== 12) {
        setPhoneError(true);
        setPhoneHelperText("Invalid phone number or incorrect length");
      }

      if (receiverNumber.length !== 12) {
        setRecPhoneError(true);
        setRecPhoneHelperText("Invalid phone number or incorrect length");
      }
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          boxShadow: 1,
          position: "fixed",
          top: 0,
          backgroundColor: "#fff",
          width: "100%",
          zIndex: 999,
        }}
      >
        {currentStep !== 0 && (
          <IconButton
            onClick={() => {
              handleBackStep(currentStep);
              ClearFields();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <img
          src={logo}
          alt=""
          width={"300vw"}
          style={{ display: "flex", margin: "auto" }}
        />
      </Box>
      {loading ? (
        <Box
          flex={1}
          sx={{
            display: "flex",
            padding: 5,
            height: "60vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress className="progress" color="secondary" />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 14,
            width: "calc(87.5%)",
            marginX: "6.5%",
            borderRadius: "10px",
            mb: 4,
          }}
        >
          {currentStep === 0 && (
            <Box
              sx={{
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                onClick={() => setCurrentStep(1)}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className={classes.k2k}
              >
                <Typography variant="h6">Kiosk to Kiosk</Typography>
              </Box>
              <Box
                onClick={() => setCurrentStep(2)}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className={classes.k2d}
              >
                <Typography variant="h6">Kiosk to Door</Typography>
              </Box>
              <Box
                onClick={() => setCurrentStep(3)}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
                className={classes.k2l}
              >
                <Typography variant="h6">Kiosk to Locker</Typography>
              </Box>
            </Box>
          )}

          {currentStep === 1 && (
            <>
              <form onSubmit={handleSubmit}>
                <SenderDetails
                  senderName={senderName}
                  setSenderName={setSenderName}
                  senderNumber={senderNumber}
                  setSenderNumber={setSenderNumber}
                  senderEmail={senderEmail}
                  setSenderEmail={setSenderEmail}
                  error={error}
                  helperText={helperText}
                  phoneError={phoneError}
                  phoneHelperText={phoneHelperText}
                  handleEmailChange={handleEmailChange}
                  handlePhoneChange={handlePhoneChange}
                />
                <div style={{ margin: "3vh" }} />
                <ReceiverDetailsKiosk
                  receiverName={receiverName}
                  setReceiverName={setReceiverName}
                  receiverNumber={receiverNumber}
                  setReceiverNumber={setReceiverNumber}
                  receiverEmail={receiverEmail}
                  setReceiverEmail={setReceiverEmail}
                  selectedKiosk={selectedKiosk}
                  setSelectedKiosk={setSelectedKiosk}
                  recerror={recerror}
                  rechelperText={rechelperText}
                  recphoneError={recphoneError}
                  recphoneHelperText={recphoneHelperText}
                  handleRecEmailChange={handleRecEmailChange}
                  handleRecPhoneChange={handleRecPhoneChange}
                />
                <div style={{ margin: "3vh" }} />
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accepted}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="I accept Terms & Conditions"
                  />
                </Box>
                <Button
                  disabled={!accepted}
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ed7d31" }}
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </>
          )}

          {currentStep === 2 && (
            <>
              <form onSubmit={handleSubmit}>
                <SenderDetails
                  senderName={senderName}
                  setSenderName={setSenderName}
                  senderNumber={senderNumber}
                  setSenderNumber={setSenderNumber}
                  senderEmail={senderEmail}
                  setSenderEmail={setSenderEmail}
                  error={error}
                  helperText={helperText}
                  phoneError={phoneError}
                  phoneHelperText={phoneHelperText}
                  handleEmailChange={handleEmailChange}
                  handlePhoneChange={handlePhoneChange}
                />
                <div style={{ margin: "3vh" }} />
                <ReceiverDetailsDoor
                  receiverName={receiverName}
                  setReceiverName={setReceiverName}
                  receiverNumber={receiverNumber}
                  setReceiverNumber={setReceiverNumber}
                  receiverEmail={receiverEmail}
                  setReceiverEmail={setReceiverEmail}
                  streetAddress={streetAddress}
                  setStreetAddress={setStreetAddress}
                  addressType={addressType}
                  setAddressType={setAddressType}
                  complex={complex}
                  setComplex={setComplex}
                  suburb={suburb}
                  setSuburb={setSuburb}
                  city={city}
                  province={province}
                  setProvince={setProvince}
                  setCity={setCity}
                  postCode={postCode}
                  setPostCode={setPostCode}
                  deliveryInstruction={deliveryInstruction}
                  setDeliveryInstruction={setDeliveryInstruction}
                  recerror={recerror}
                  rechelperText={rechelperText}
                  recphoneError={recphoneError}
                  recphoneHelperText={recphoneHelperText}
                  handleRecEmailChange={handleRecEmailChange}
                  handleRecPhoneChange={handleRecPhoneChange}
                />
                <div style={{ margin: "3vh" }} />
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accepted}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="I accept Terms & Conditions"
                  />
                </Box>
                <Button
                  disabled={!accepted}
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ed7d31" }}
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </>
          )}
          {currentStep === 3 && (
            <>
              <form onSubmit={handleSubmit}>
                <SenderDetails
                  senderName={senderName}
                  setSenderName={setSenderName}
                  senderNumber={senderNumber}
                  setSenderNumber={setSenderNumber}
                  senderEmail={senderEmail}
                  setSenderEmail={setSenderEmail}
                  error={error}
                  helperText={helperText}
                  phoneError={phoneError}
                  phoneHelperText={phoneHelperText}
                  handleEmailChange={handleEmailChange}
                  handlePhoneChange={handlePhoneChange}
                />
                <div style={{ margin: "3vh" }} />
                <ReceiverDetailsLocker
                  receiverName={receiverName}
                  setReceiverName={setReceiverName}
                  receiverNumber={receiverNumber}
                  setReceiverNumber={setReceiverNumber}
                  receiverEmail={receiverEmail}
                  setReceiverEmail={setReceiverEmail}
                  selectedLocker={selectedLocker}
                  setSelectedLocker={setSelectedLocker}
                  recerror={recerror}
                  rechelperText={rechelperText}
                  recphoneError={recphoneError}
                  recphoneHelperText={recphoneHelperText}
                  handleRecEmailChange={handleRecEmailChange}
                  handleRecPhoneChange={handleRecPhoneChange}
                />
                <div style={{ margin: "3vh" }} />
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accepted}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label="I accept Terms & Conditions"
                  />
                </Box>
                <Button
                  disabled={!accepted}
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: "#ed7d31" }}
                  fullWidth
                >
                  Submit
                </Button>
              </form>
            </>
          )}
        </Box>
      )}

      <Box
        sx={{
          backgroundColor: "#264796",
          height: "8vh",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          position: currentStep === 0 ? "fixed" : "sticky",
          bottom: 0,
          width: "100%",
          zIndex: 998,
        }}
      >
        <Typography
          color={"#ffffff"}
          onClick={() => handleTerms()}
          sx={{ cursor: "pointer" }}
        >
          Terms and Conditions
        </Typography>
      </Box>

      <Modal
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Thank you for your booking:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your waybill # is {apiResponse.short_tracking_reference}
            {error && <p>Error: {error.message}</p>}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please give this to the Kiosk Assistant at your closest kiosk.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You will receive an SMS shortly with your waybill #.
          </Typography>
          <Button onClick={closeModal}>Close</Button>
        </Box>
      </Modal>

      <Modal
        open={errorModal}
        onClose={closeErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            {errorMessage}
          </Typography>

          <Button variant="outlined" onClick={closeErrorModal}>Retry</Button>
        </Box>
      </Modal>

    </Fragment>
  );
}

export default App;
